<template>
  <section class="contact-us" v-if="$route.name === 'contacts'">
    <div class="contact-us__border"></div>
    <div class="container">
      <div class="contact-us__title">
        <h1>{{$t('contacts_page.title')}}</h1>
      </div>
      <div class="contact-us__text">{{$t('contacts_page.text')}}</div>

      <div class="contact-us__main">
        <div class="contact-us__form">

          <div class="contact-us__bg"></div>
          <form action="" ref="form" @submit.prevent="sendEmail">
            <div class="contact-us__input">
              <input class="form__input"
                     type="text"
                     name="name"
                     v-model="name"
                     :placeholder="$t('main_page.contact_section.form_name')"
                     :class="{ 'form__input-error' : this.errorsInput[0].error}"
              >
              <span v-if="this.errorsInput[0].error">{{$t('main_page.contact_section.validation_error_name')}}</span>
            </div>

            <div class="contact-us__input">
              <input
                  class="form__input"
                  type="email"
                  name="email"
                  v-model="email"
                  :placeholder="$t('main_page.contact_section.form_email')"
                  :class="{ 'form__input-error' : this.errorsInput[1].error}"
              >
              <span v-if="this.errorsInput[1].error">{{$t('main_page.contact_section.validation_error_email')}}</span>
            </div>
<!--commen input-->
   <div class="contact-us__input">
              <input class="form__input"
                     type="text"
                     name="comment"
                     v-model="comment"
                     :placeholder="$t('main_page.contact_section.form_comment')"
                     :class="{ 'form__input-error' : this.errorsInput[2].error}"
              >
              <span v-if="this.errorsInput[2].error">{{$t('main_page.contact_section.validation_error_comment')}}</span>
            </div>
            <div class="contact-us__policy">
              <label>
                <input type="checkbox" name="agree" v-model="agree_checkbox">
                <span class="contact-us__policy-checkbox"></span>
                <div>
                  {{$t('contacts_page.form_agree')}}
<!--                  I agree to <router-link class="contact-us__policy-link" to="">the Terms and Conditions</router-link> and consent to Webmil Web-production saving and-->
<!--                  processing my data according to their-->
<!--                  <router-link class="contact-us__policy-link" to="">-->
<!--                    Privacy Policy-->
<!--                  </router-link>.-->
                </div>
              </label>
            </div>
            <div class="contact-us__policy">
              <label>
                <input type="checkbox" name="would" v-model="would_checkbox">
                <span class="contact-us__policy-checkbox"></span>
                <div>{{$t('contacts_page.form_send_letter')}}</div>
              </label>
            </div>
            <button class="btn__general btn__general-black contact-us__submit" type="submit">{{$t('contacts_page.button')}}
              <svg width="31" height="8" viewBox="0 0 31 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M30.3536 4.35355C30.5488 4.15829 30.5488 3.84171 30.3536 3.64645L27.1716 0.464466C26.9763 0.269204 26.6597 0.269204 26.4645 0.464466C26.2692 0.659728 26.2692 0.976311 26.4645 1.17157L29.2929 4L26.4645 6.82843C26.2692 7.02369 26.2692 7.34027 26.4645 7.53553C26.6597 7.7308 26.9763 7.7308 27.1716 7.53553L30.3536 4.35355ZM0 4.5H30V3.5H0V4.5Z" fill="#2E3139"/>
              </svg>
            </button>
          </form>
        </div>

        <div class="contact-us__info">
          <div class="contact-us__info-title">{{$t('main_page.contact_section.subtitle')}}</div>
          <ol class="contact-us__info-list">
            <li>{{$t('main_page.contact_section.contact_list[0]')}}</li>
            <li>{{$t('main_page.contact_section.contact_list[1]')}}</li>
            <li>{{$t('main_page.contact_section.contact_list[2]')}}</li>
          </ol>

          <div class="contact-us__info-nda">
            {{$t('main_page.contact_section.nda')}}
          </div>
        </div>
      </div>

      <div class="contact-us__call">
        <div class="contact-us__call-title">Contacts</div>
        <div class="contact-us__call-inner">
          <div class="contact-us__call-item">
            <span>{{$t('contacts_page.info_and_hr')}}</span>

            <div class="contact-us__call-email">
              <a href="mailto: hr@webmil.eu">hr@webmil.eu</a>
            </div>
            <div class="contact-us__call-phone">
              <!-- <a href="tel: 380960236363">+38 096 023 63 63</a> -->
            </div>
          </div>


          <div class="contact-us__call-item">
            <span>{{$t('contacts_page.sales')}}</span>

            <div class="contact-us__call-email">
              <a href="mailto: sales@webmil.eu">sales@webmil.eu</a>
            </div>
            <div class="contact-us__call-phone">
              <a href="tel: 380668089011">+38 066 808 90 11</a>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="pop-up__confirmation" :class="{'pop-up__confirmation-active': isPopUpConfirmationVisible}">
      <div class="pop-up__confirmation-title">Cool! We got your request</div>
      <div class="pop-up__confirmation-text">Our manager will contact you within 24 working business hours
      </div>
    </div>
  </section>
</template>

<script>
import emailjs from 'emailjs-com';
import {computed, reactive} from "vue";
import {useHead} from "@vueuse/head";

export default {
  name: "Contact",
  setup() {
    const siteData = reactive({
      title: "Contact Us | Webmil Web-production",
      description: "Get a discount if you require an update of your product: free UX and technical analysis. Submit details about your project on this page.",
      keywords: "contact us",
      robots: "index"
    })

    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description)
        },
        {
          name: `keywords`,
          content: computed(() => siteData.keywords)
        },
        {
          name: `robots`,
          content: computed(() => siteData.robots)
        },
      ],
    })
  },
  data() {
    return {
      name: '',
      email: '',
      comment: '',
      agree_checkbox: false,
      would_checkbox: false,
      errorsInput: [
        {error: ''},
        {error: ''},
        {error: ''},
      ],
      isPopUpConfirmationVisible: false
    }
  },
  methods: {
    sendEmail(e) {
      this.validateName();
      this.validateEmail();
      this.validateComment();

      if (!this.errorsInput[0].error && !this.errorsInput[1].error && !this.errorsInput[2].error) {
        emailjs.sendForm(
            'service_u4lvihc',
            'template_zhu67ce',
            e.target,
            'OGOmyZess3dbFvuxM'
        )
            .then((result) => {
              console.log('SUCCESS!', result.text);
            }, (error) => {
              console.log('FAILED...', error.text);
            });

        this.name = ''
        this.email = ''
        this.comment= ''
        this.makePopUpConfirmationVisible();
      }
    },
    validateName() {
      this.name = this.name.trim();
      if (!this.name) {
        this.errorsInput[0].error = "Please type name";
      } else {
        this.errorsInput[0].error = "";
      }
    },

    validateEmail() {
      this.email = this.email.trim();
      if (!this.email) {
        
        this.errorsInput[1].error = "Please type email";
      } else {
        
        this.errorsInput[1].error = "";
      }
    },
    validateComment(){
      this.comment = this.comment.trim();
      if (this.comment.length <= 24) {
        this.errorsInput[2].error = "Please type comment";
      } else {
        this.errorsInput[2].error = "";
      }
    },

    makePopUpConfirmationVisible() {
      this.isPopUpConfirmationVisible = true;
      setTimeout(() => {
        this.isPopUpConfirmationVisible = false;
      }, 4000);
    }
  },

}
</script>

<style lang="scss">

.pop-up__confirmation{
  position: fixed;
  right: 20px;
  transform: translateX(calc(100% + 20px));
  bottom: 20px;
  z-index: 1000;
  width: 400px;
  background-color: #253746;
  padding: 30px 54px;
  color: #FFF;
  transition: 0.3s all;
  @media(max-width: 575px){
    right: 12px;
    transform: translateX(calc(100% + 12px));
    width: calc(100% - 24px);
    padding: 20px 58px;
    top: 20px;
    bottom: auto;
  }
  &-title{
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;

    @media(max-width: 575px){
      font-size: 18px;
      line-height: 28px;
    }
  }
  &-text{
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    margin-top: 10px;
    @media(max-width: 575px){
      font-size: 14px;
      line-height: 24px;
    }
  }
}

.pop-up__confirmation-active{
  transform: translateX(0);
  //right: 20px;
  //@media(max-width: 575px){
  //  right: 15px;
  //}
}

.contact-us {
  margin-top: 140px;
  padding-bottom: 36px;

  @media(max-width: 991px) {
    margin-top: 135px;
  }

  @media(max-width: 575px) {
    margin-top: 96px;
  }
  a{
    transition: 0.7s color;
  }
  a:hover{
    color: #688E93;
  }
  @media(min-width: 992px){
    position: relative;
    &:before{
      content: '';
      position: absolute;
      top: 162px;
      right: 0;
      width: 50%;
      display: block;
      border-top: 1px solid #F2F2F2;
    }

    &:after{
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 50%;
      display: block;
      border-top: 1px solid #F2F2F2;
    }
  }

  &__border{
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 1.49px;
    height: 75.5%;
    z-index: 2;
    transform: translateX(-50%);
    background-color: #F2F2F2;

    @media(max-width: 991px){
      display: none;
    }
  }


  // .contact-us__title
  &__title {
    h1{
      font-weight: 700;
      font-size: 72px;
      line-height: 72px;

      @media(max-width: 991px){
        font-size: 64px;
        line-height: 68px;
      }

      @media(max-width: 575px){
        font-size: 48px;
        line-height: 60px;
      }

    }
  }

  // .contact-us__text
  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-top: 36px;

    @media(max-width: 991px){
      margin-top: 20px;
    }

    @media(max-width: 575px){
      margin-top: 12px;
    }
  }

  // .contact-us__main
  @media(min-width: 992px){
    &__main {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
    }
  }

  &__main{
    @media(max-width: 575px) {
      margin-top: 12px;
    }
  }


  form{
    display: flex;
    flex-direction: column;
  }

  // .contact-us__form
  &__form {
    flex: 0 0 50%;
    padding-right: 200px;
    padding-top: 36px;
    padding-bottom: 36px;
    position: relative;

    @media(max-width: 1399px){
      padding-right: 160px;
      background-position: -170px 0px;
    }

    @media(max-width: 1199px){
      background-position: -260px 0px;
      padding-right: 100px;
    }

    @media(max-width: 991px){
      background-position: 0px 0px;
      padding-right: 30px;
      padding-left: 30px;
    }

    @media(max-width: 767px){
      background-position: -150px 0px;
    }

    @media(max-width: 575px){
      background-position: -75px 0px;
      padding-top: 24px;
      padding-right: 12px;
      padding-left: 12px;
      margin-left: -12px;
      margin-right: -12px;
    }

    @media(max-width: 420px){
      background-position: -200px 0px;
    }

    &:before{
      content: '';
      display: block;
      position: absolute;
      bottom: -80px;
      right: 120px;
      z-index: -2;
      background-image: url("../assets/contact_form_circle.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      width: 300px;
      height: 300px;

      @media (max-width: 1399px) {
        right: 90px;
      }

      @media (max-width: 1199px) {
        right: 70px;
      }

      @media (max-width: 575px) {
        width: 200px;
        height: 200px;
        right: 35px;
        bottom: -55px;
      }
    }

    button{
      background: transparent;
      align-self: flex-end;
      margin-top: 24px;
    }
  }

  &__bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;

    @supports (backdrop-filter: blur(30px)) {
      & {
        backdrop-filter: blur(30px);
        -webkit-filter: blur(30px);
        -moz-filter: blur(30px);
        -o-filter: blur(30px);
        -ms-filter: blur(30px);
        filter: blur(30px);
      }
    }

    @supports not (backdrop-filter: blur(30px)){
      & {
        background-image: url("../assets/contact_form-bg.png");
        background-position: -80px 0px;
        background-repeat: no-repeat;
        background-size: cover;

        @media(max-width: 1399px){
          padding-right: 160px;
          background-position: -170px 0px;
        }

        @media(max-width: 1199px){
          background-position: -260px 0px;
        }

        @media(max-width: 991px){
          background-position: 0px 0px;
        }

        @media(max-width: 767px){
          background-position: -150px 0px;
        }

        @media(max-width: 575px){
          background-position: -75px 0px;
        }

        @media(max-width: 420px) {
          background-position: -200px 0px;
        }
      }
    }
  }

  // .contact-us__input
  &__input {
    &:not(:first-child){
      margin-top: 24px;
    }

    input{
      width: 100%;
    }

    span{
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #D93737;
    }

    .form__input-error{
      border-bottom: 1px solid #D93737;
    }
  }

  // .contact-us__policy
  &__policy {
    display: flex;
    align-items: flex-start;
    padding-left: 39px;
    margin-top: 24px;
    max-width: 350px;

    @media(max-width: 991px){
      max-width: 498px;
    }

    label{
      display: flex;
      cursor: pointer;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #4F4F4F;
    }

    input{
      position: absolute;
      width: 1px;
      height: 1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
    }

    span{
      position: absolute;
      width: 20px;
      height: 20px;
      border: 2px solid #688E93;
      border-radius: 1px;
      margin-left: -39px;
      margin-top: 2px;
    }

    input:checked + span{
      background-image: url("../assets/form_tick.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 20px;
      background-color: #688E93;
    }

    &-link{
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #688E93 !important;
      text-decoration: underline;
    }

  }

  // .contact-us__submit
  &__submit {
    &:active{
      background: transparent;
    }
  }

  // .contact-us__info
  &__info {
    flex: 0 0 50%;
    padding-top: 36px;
    color: #2E3139;


    @media(max-width: 991px){
      margin-top: 86px;
    }

    @media(min-width: 992px) {
      padding-left: 105px;
    }

    // .contact-us__info-title
    &-title {
      font-weight: 700;
      font-size: 36px;
      line-height: 48px;

      @media(max-width: 991px){
        font-size: 32px;
        line-height: 44px;
      }

      @media(max-width: 575px){
        font-size: 24px;
        line-height: 30px;
      }
    }

    // .contact-us__info-list
    &-list{
      margin-top: 18px;
      margin-left: 16px;
    }

    &-list > li {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }

    // .contact-us__info-nda
    &-nda {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      margin-top: 18px;
    }
  }

  // .contact-us__call
  &__call {
    color: #2E3139;

    @media(max-width: 991px){
      margin-top: 60px;
    }

    @media(max-width: 575px){
      margin-top: 36px;
    }



    @media(min-width: 992px){
      width: 50%;
      padding-left: 105px;
      margin-left: auto;
    }

    &-inner{
      display: flex;

      @media(max-width: 991px){
        margin-top: 18px;
      }
    }

    &-item{
      flex: 0 0 118px;
    }

    &-item:not(:first-child){
      margin-left: 24px;
    }

    &-title{
      display: none;
      font-weight: 700;
      font-size: 32px;
      line-height: 44px;

      @media(max-width: 991px){
        display: block;
      }

      @media(max-width: 575px){
        font-size: 24px;
        line-height: 30px;
      }
    }


    span{
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      text-transform: uppercase;
    }

    // .contact-us__call-email
    &-email > a {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }

    // .contact-us__call-phone
    &-phone > a {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }
  }
}

</style>
